export const BRAND_PRIMARY = "rgb(172, 94, 35)";
export const BRAND_SECONDARY = "rgb(252, 248, 235)";
export const NORMAL_FONT_COLOR = "rgb(51, 45, 43)";

export const WEDDING_COLORS = [
  "#EB7830",
  "#732510",
  "#6E6434",
  "#375466",
  "#EDD6B3",
  "#C4C9C2",
  "#755D6E"
];
