interface TimelineItem {
  time: string;
  title: string;
  description?: string;
}

export const ceremonyTimelineItems: TimelineItem[] = [
  {
    time: "2:30 PM",
    title: "Guests arrival",
    description:
      "Join us in St. Patrick's Lilydale, please arrive early as parking is limited"
  },
  {
    time: "3:00 PM",
    title: "Wedding mass",
    description: "Witness us exchange our wedding vows"
  },
  { time: "4:00 PM", title: "Group photos" }
];

export const receptionTimelineItems: TimelineItem[] = [
  {
    time: "5:00 PM",
    title: "Guests arrival",
    description: "Relax, mingle, take photos"
  },
  {
    time: "5:30 PM",
    title: "Reception doors open",
    description: "Bar opens, photobooth opens, canapes to be served"
  },
  {
    time: "6:15 PM",
    title: "Bridal party entrance",
    description: "Meet the bridal party"
  },
  {
    time: "6:30 PM",
    title: "Grand entrance",
    description:
      "Witness us exchange our personal promise and have our 1st dance as a married couple"
  },
  {
    time: "7:00 PM",
    title: "Dinner",
    description: "Let's enjoy a delicious meal together"
  },
  {
    time: "8:30 PM",
    title: "Speeches and toasts",
    description:
      "Hear from our loved ones as they share their words of wisdom and love"
  },
  { time: "9:00 PM", title: "Cake cutting" },
  {
    time: "9:30 PM",
    title: "Desserts and dancing",
    description: "Sweet treats or dancing? Your choice!"
  },
  {
    time: "10:30 PM",
    title: "Reception concludes",
    description: "Thanks for celebrating with us!"
  }
];

const TimelineItem = (item: TimelineItem) => {
  return (
    <p className="timeline-item fs-6 mb-2">
      {item.time} - <strong>{item.title}</strong>
      {item.description && (
        <>
          {" - "} <i>{item.description}</i>
        </>
      )}
    </p>
  );
};

export default TimelineItem;
