import { Fragment } from "react/jsx-runtime";

import TimelineItem, {
  ceremonyTimelineItems,
  receptionTimelineItems
} from "components/landing/TimelineItem";

const Timeline = () => {
  return (
    <Fragment>
      <div className="p-5 d-flex justify-content-center bg-brand-primary text-white brand-text"></div>
      <section className="section-timeline bg-brand-secondary p-5">
        <h2
          className="brand-text mb-5 text-center"
          style={{
            fontSize: 50
          }}
        >
          Wedding Timeline
        </h2>
        <div className="timeline-container">
          <h4>Ceremony</h4>
          {ceremonyTimelineItems.map((item) => (
            <TimelineItem key={item.time} {...item} />
          ))}
          <h4 className="pt-4">Reception</h4>
          {receptionTimelineItems.map((item) => (
            <TimelineItem key={item.time} {...item} />
          ))}
        </div>
      </section>
    </Fragment>
  );
};

export default Timeline;
