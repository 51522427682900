import { Fragment } from "react/jsx-runtime";

const Registry = () => {
  return (
    <Fragment>
      <section className="section-registry-1 bg-brand-primary text-white brand-text">
        <h1>Wedding Registry</h1>
      </section>
      <section className="section-registry-2 mx-0 vw-100 bg-brand-secondary">
        <div className="container py-5 d-flex justify-content-between align-items-center">
          <div
            className="text-container text-center"
            style={{
              fontSize: 19
            }}
          >
            <p
              className="brand-text text-brand-primary mb-3"
              style={{
                fontSize: 40
              }}
            >
              Note on gifts
            </p>
            <p className="brand-text">
              Thank you for your love, support, and care.
            </p>
            <p className="brand-text">
              We’re so grateful for your presence here, to celebrate our love
              and cheer.
            </p>
            <p className="brand-text">
              If you’d like to contribute to our wishing well, you’ll help us
              create memories tonight and forever. A gift of money is placed in
              the well. Then make a wish… but do not tell.
            </p>
            <p className="brand-text">
              Whether big or small, it's the thought that counts, and we'll be
              thankful for every penny that amounts.
            </p>
            <p className="brand-text">
              If, however, a gift you’d prefer to find, we have arranged a{" "}
              <a
                href="https://www.amazon.com.au/hz/wishlist/ls/2NI58K91QG269?ref_=wl_share"
                className="text-decoration-none"
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  color: "green"
                }}
              >
                Gift Registry
              </a>
            </p>
            <p className="brand-text">
              It means the world to us that on our wedding day, you were there.
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Registry;
