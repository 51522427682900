import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import ErrorPage from "components/common/ErrorPage";
import Layout from "components/common/Layout";
import Landing from "components/landing/Landing";
import OurStory from "components/our-story/OurStory";
import Registry from "components/registry/Registry";
import Timeline from "components/timeline/Timeline";

import "assets/scss/main.scss";

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />
      },
      {
        path: "/our-story",
        element: <OurStory />
      },
      {
        path: "/registry",
        element: <Registry />
      },
      {
        path: "/timeline",
        element: <Timeline />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
