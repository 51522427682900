import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

import { WEDDING_COLORS } from "constants/colors";

import RingsIcon from "components/icons/RingsIcon";

import useAAMWStore from "store/useAAMWStore";

import Home from "./Home";
import Secret from "./SecretSection";

import bookIcon from "assets/img/book.png";
import ceremonyPhoto from "assets/img/ceremony.jpg";
import coverPhoto from "assets/img/photo-3.jpg";
import receptionPhoto from "assets/img/receptionPhoto.jpg";
import registryIcon from "assets/img/registry.png";
import timelineIcon from "assets/img/timeline.png";

const Landing = () => {
  const { isUserValidated } = useAAMWStore();

  return (
    <Fragment>
      <section
        className="position-relative section-cover vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url('${coverPhoto}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed"
        }}
      >
        {!isUserValidated ? <Secret /> : <Home />}
      </section>
      {isUserValidated && (
        <Fragment>
          <section className="rings-section px-5 bg-brand-primary text-white brand-text">
            <div className="left-content">
              <h3
                style={{
                  fontSize: 50
                }}
              >
                The Wedding Day
              </h3>
              <p className="fs-4 mb-0">Thursday, April 24 2025, 3 p.m.</p>
            </div>
            <div className="rings-container">
              <RingsIcon />
            </div>
            <div className="right-content">
              <h4 className="fs-2">Where to stay</h4>
              <span className="fs-5">
                We recommend a BNB near Forest Hill, which is where we live.
                Then car/uber from there
              </span>
            </div>
          </section>

          <section className="section-reception d-flex bg-brand-secondary">
            <div className="reception-container text-normal">
              <div className="img-container">
                <img src={ceremonyPhoto} alt="Ceremony" />
                <div className="img-texts brand-text px-4">
                  <h2 className="brand-text">Ceremony</h2>
                  <p>
                    Thursday April 24 2025, 3 p.m. St. Patrick's Church 67
                    Clarke St Lilydale VIC 3140
                  </p>
                </div>
              </div>
              <div className="img-container">
                <img src={receptionPhoto} alt="" />
                <div className="img-texts brand-text px-4">
                  <h2 className="brand-text">Reception</h2>
                  <p>
                    Thursday April 24 2025, 5.30 p.m. The Little Vineyard 17
                    Paynes Rd, Chirnside Park VIC 3116
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="section-links d-flex bg-brand-primary">
            <div className="container py-5">
              <h3 className="brand-text text-white text-center">
                Check out these pages
              </h3>
              <div className="d-flex flex-wrap justify-content-around pb-4">
                <Link to="/registry" className="text-decoration-none mt-5">
                  <div className="card link-card flex flex-column align-items-center bg-brand-secondary justify-content-center">
                    <img
                      src={registryIcon}
                      alt="Registry"
                      style={{
                        height: "55%",
                        width: "55%",
                        opacity: 0.8
                      }}
                    />
                    <p
                      className="text-normal text-center"
                      style={{ fontWeight: 600, marginBottom: "-10px" }}
                    >
                      Registry
                    </p>
                  </div>
                </Link>
                <Link
                  to="/our-story"
                  className="section-links-wrapper text-decoration-none mt-5"
                >
                  <div className="card link-card flex flex-column align-items-center bg-brand-secondary justify-content-center">
                    <img
                      src={bookIcon}
                      alt="Registry"
                      style={{
                        height: "70%",
                        width: "70%",
                        opacity: 0.6,
                        marginBottom: "-10px"
                      }}
                    />
                    <p
                      className="text-normal text-center"
                      style={{ fontWeight: 600 }}
                    >
                      Our Story
                    </p>
                  </div>
                </Link>
                <Link
                  to="/timeline"
                  className="section-links-wrapper text-decoration-none mt-5"
                >
                  <div className="card link-card flex flex-column align-items-center bg-brand-secondary justify-content-center">
                    <img
                      src={timelineIcon}
                      alt="Timeline"
                      style={{
                        height: "70%",
                        width: "70%",
                        opacity: 0.6,
                        marginBottom: "-10px"
                      }}
                    />
                    <p
                      className="text-normal text-center"
                      style={{ fontWeight: 600 }}
                    >
                      Timeline
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </section>

          <section className="section-colors d-flex bg-brand-secondary justify-content-center">
            <div className="section-colors-container">
              <h2 className="brand-text text-normal">
                Motif for guests is autumn colors
              </h2>
              <div className="colors-container">
                {WEDDING_COLORS.map((color) => (
                  <div
                    key={color}
                    className="wedding-color-circle"
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
              <h4 className="brand-text text-normal mt-4">
                Please avoid wearing "rust" and "navy blue"
              </h4>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Landing;
